body.loading #pages > div:not(.MuiSuspensLoader-root) {
    filter:blur(3px) grayscale(1)
}
.initial-loader {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100vh;
    animation: logo-flip 300ms ease 0s 1;
}
.initial-loader svg {
    font-size:100px;
}

.initial-loader .title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 16px;
}

@keyframes logo-flip {
    0% {
        transform:scale(2.5)
    }
    100% {
        transform: scale(1)
    }
}